import common from '@/mixin/common.js';
import { mapActions, mapGetters } from 'vuex';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

export default {
  name: 'r-home',
  mixins: [common],
  components: {
    swiper,
    swiperSlide,
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      // middleBgImage: require('@/assets/home/home_bg.jpg'),
      p1BgImage: require('@/assets/home/p1_bg.jpg'),
      p2BgImage: require('@/assets/home/p2_bg_top.jpg'),
      p2BgImage2: require('@/assets/home/p2_bg_bottom.jpg'),
      p2MaskImage: require('@/assets/home/p2_mask.png'),
      productInfo: [
        require('@/assets/home/product_1.png'),
        require('@/assets/home/product_2.gif'),
      ],
      productList: [
        {
          selectImage: require('@/assets/home/1_select.png'),
          image: require('@/assets/home/1.png'),
          isShow: false,
        },{
          selectImage: require('@/assets/home/2_select.png'),
          image: require('@/assets/home/2.png'),
          isShow: false,
        },{
          selectImage: require('@/assets/home/3_select.png'),
          image: require('@/assets/home/3.png'),
          isShow: false,
        },{
          selectImage: require('@/assets/home/4_select.png'),
          image: require('@/assets/home/4.png'),
          isShow: false,
        },{
          selectImage: require('@/assets/home/5_select.png'),
          image: require('@/assets/home/5.png'),
          isShow: false,
        },{
          selectImage: require('@/assets/home/6_select.png'),
          image: require('@/assets/home/6.png'),
          isShow: false,
        }
      ],
      footPCSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      },
      footMobileSwiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      },
      bannerSwiperOption: {
        autoplay: true,
        delay: 5000,
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      },
    }
  },
  created() {
    this.getBannerList();
  },
  mounted() {
    if (!this.newList || !this.newList.length) {
      this.getNewList();
    } else {
      this.resetPagina();
    }
  },
  methods: {
    ...mapActions('home', ['getBannerList', 'getNewList']),
    showBuy() {
      this.$router.push('/product');
    },
	toPage(item){
		if(item.url != null && item.url !== ''){
			this.$router.push(item.url)
		}
	},
    resetPagina() {
      try {
        if (!document.getElementById('scroll_info')) return;
        const currWidth = document.getElementById('scroll_info').offsetWidth;
        const singleWidth = 390;
        const spaceBetween = 30;
        let slidesOffsetAfter = currWidth - singleWidth;
        if (this.newList.length * singleWidth < currWidth) {
          // 列表内容小于容器
          slidesOffsetAfter -= currWidth - (this.newList.length * singleWidth);
        } else {
          // 列表内容大于容器
          slidesOffsetAfter += spaceBetween;
        }
        this.footPCSwiperOption = { 
          slidesPerView: 'auto',
          spaceBetween: spaceBetween,
          slidesOffsetAfter: slidesOffsetAfter,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletClass: 'my-bullet',
            bulletActiveClass: 'my-bullet-active',
            renderBullet: function (index, className) {
              return '<span class="' + className + '" onmouseover="this.click()"></span>';

              // var slide = $('.' + this.wrapperClass).find('.headermainslider')[index];
              // return '<span class="' + className + '"><a href="'+$(slide).attr('data-rel')+'" >' + $(slide).attr('data-value') + '</a></span>';
            }
          }
        }
      } catch (error) {
        console.log(error)
        setTimeout(()=>{
          this.resetPagina();
        }, 100)
      }
    },
    showTectnology() {
      this.$router.push('/technology');
    },
    showNewsDetail(params) {
      if (!params || !params.code) {
        this.$router.push('/news');
      } else {
        this.$router.push('/news/detail/' + params.code);
      }
    },
    changeFoot(params) {
      if (!params) {
        this.$refs.footSwiper.swiper.slideNext();
      } else {
        this.$refs.footSwiper.swiper.slidePrev();
      }
    }
  },
  watch: {
    newList: {
      handler: function() {
        this.resetPagina();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('home', ['bannerList', 'newList']),
    isShowArrow() {
      return (this.newList && this.newList.length >= 3)
    }
  },
}